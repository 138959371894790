import {
    Container,
    Divider,
    IconButton, LinearProgress,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {search} from "./searchService";
import {useDebounce} from "../utils/debounce";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {Product} from "../product/models";
import {useNavigate} from "react-router-dom";

export default function SearchView({barcode, setBarcode}: { barcode: string, setBarcode: (code: string) => void }) {
    const navigate = useNavigate()
    const [products, setProducts] = useState<Array<Product>>([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(barcode);
    const [usedBarcode, setUsedBarcode] = useState("");

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchTerm(e.target.value);
        searchForProducts();
    }

    const searchForProducts = useDebounce(500, () => {
        setLoading(true)
        search(searchTerm)
            .then((products) => setProducts(products))
            .catch((error) => console.log(error.message))
            .finally(() => {
                setLoading(false)
                setUsedBarcode(searchTerm)
            });
    })

    if (barcode.length > 0 && searchTerm === barcode && barcode !== usedBarcode) {
        searchForProducts()
    }

    const results = products.map((product) => {
        let title = product.productName;
        if (product.brands != null && product.brands.length > 0) {
            title += ` (${product.brands.join(", ")})`
        }

        return <>
            <ListItem onClick={() => {
                navigate("/products/" + product.id)
            }}>
                <ListItemText
                    primary={title}
                    secondary={product.quantity}
                />
                <Stack direction="column">
                    <img src={`/image/nutriscore-${product.nutriScore}.svg`} width={50} alt="nutriscore"/>
                </Stack>
            </ListItem>
            <Divider/>
        </>
    });

    return <>
        <Container maxWidth="sm" sx={{padding: 2}}>
            <Stack direction="row" spacing={1}>
                <TextField
                    id="standard-search"
                    label="Szykaj"
                    type="search"
                    variant="outlined"
                    sx={{width: '100%'}}
                    value={searchTerm}
                    onChange={handleChange}
                />
                <IconButton color="primary" onClick={() => {
                    setBarcode("")
                    navigate("/scan")
                }}>
                    <QrCodeScannerIcon/>
                </IconButton>
            </Stack>
            {loading && <LinearProgress/>}
            <List dense={false} sx={{width: '100%', maxWidth: 500, bgcolor: 'background.paper'}}>
                {results}
            </List>
        </Container>
    </>;
}