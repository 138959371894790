import {
    Button,
    Stack, TextField,
    Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthContextProvider";
import {login} from "./authService";

export default function Login() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [input, setInput] = useState({
        email: "",
        password: "",
    });

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInput((prev) => ({
            ...prev,
            email: e.target.value,
        }));
    }

    function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInput((prev) => ({
            ...prev,
            password: e.target.value,
        }));
    }

    function handleLogin() {
        if (input.email === "" || input.password === "") return;

        login(input)
            .then((token) => {
                auth.setToken(token);
                navigate("/");
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <Stack sx={{height: window.innerHeight}}>
            <Stack
                width="100%"
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Typography variant="h4" align="center">
                    Logowanie
                </Typography>

                <TextField sx={{width: '25ch'}}
                           id="email"
                           type="email"
                           label="Email"
                           onChange={handleEmailChange}
                           autoFocus
                />
                <TextField sx={{width: '25ch'}}
                           id="password"
                           type="password"
                           label="Hasło"
                           onChange={handlePasswordChange}
                />

                <Button variant="contained" onClick={handleLogin}>Zaloguj sie</Button>
                <Button variant="text" href="/registration">Zarejestruj się</Button>

            </Stack>
        </Stack>
    )
}