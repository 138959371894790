import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import Token from "./token";

interface AuthContextProps {
    token: Token;
    setToken: Dispatch<SetStateAction<Token>>;
    cleanToken: () => void;
}

const emptyToken = {accessToken: "", refreshToken: "", expiresIn: 0};

export const AuthContext = createContext<AuthContextProps>({
    token: emptyToken,
    setToken(): void {
    },
    cleanToken(): void {
    },
});

export default function AuthContextProvider({children}: any) {
    const storedTokenString = localStorage.getItem("token")
    let storedToken
    if (storedTokenString) {
        storedToken = JSON.parse(storedTokenString)
    }

    const [token, setToken] = useState<Token>(storedToken || emptyToken);

    useEffect(() => {
        localStorage.setItem("token", JSON.stringify(token));
    }, [token]);

    const authProps: AuthContextProps = {
        token: token,
        setToken: setToken,
        cleanToken: () => setToken(emptyToken),
    };

    return (
        <AuthContext.Provider value={authProps}>{children}</AuthContext.Provider>
    );
}
