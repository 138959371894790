import {useEffect, useState} from "react";
import {ProductDetails} from "./models";
import {useParams} from "react-router-dom";
import {getProductDetails} from "./productDetailsService";
import * as React from "react";
import ProductDetailsView from "./ProductDetailsView";

export default function ProductDetailsPage() {
    const [productDetails, setProductDetails] = useState<ProductDetails | null>(null)
    const params = useParams<{ id: string }>();

    useEffect(() => {
        getProductDetails(params.id as string)
            .then(result => setProductDetails(result))
            .catch((error) => {
                console.log(error.message);
            });
    }, [params.id])

    if (!productDetails) return <></>

    return <ProductDetailsView productDetails={productDetails}/>
}