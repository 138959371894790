import {IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

import Face4SharpIcon from '@mui/icons-material/Face4Sharp';
import FaceSharpIcon from '@mui/icons-material/FaceSharp';
import React from "react";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function GenderSelectorView({gender, setGender, onNext}: {
    gender: string,
    setGender: (value: string) => void,
    onNext: () => void
}) {
    return (
        <Stack
            width="100%"
            height="100%"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Typography variant="h4" align="center">
                Jakiej płci jesteś?
            </Typography>

            <ToggleButtonGroup
                exclusive
                orientation="vertical"
                value={gender}
                onChange={(e, newAlignment) => {
                    setGender(newAlignment)
                }}>
                <ToggleButton value="Female">
                    <Face4SharpIcon color="primary" sx={{fontSize: 150}}/>
                </ToggleButton>
                <ToggleButton value="Male">
                    <FaceSharpIcon color="primary" sx={{fontSize: 150}}/>
                </ToggleButton>
            </ToggleButtonGroup>

            <IconButton
                color="primary"
                onClick={onNext}
                disabled={gender.length === 0}
            >
                <ArrowCircleRightIcon sx={{fontSize: 60}}/>
            </IconButton>

        </Stack>
    )
}