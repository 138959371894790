import {IconButton, Stack, Typography} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import React from "react";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";
import dayjs, {Dayjs} from "dayjs";

export default function BirthdaySelectorView({birthday, setBirthday, onNext}: {
    birthday: Dayjs,
    setBirthday: (value: Dayjs) => void,
    onNext: () => void
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <Stack
                width="100%"
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <Typography variant="h4" align="center">
                    Jaka jest Twoja data urodzenia?
                </Typography>

                <DatePicker
                    value={birthday}
                    minDate={dayjs().subtract(100, 'years')}
                    maxDate={dayjs().subtract(18, 'years')}
                    onChange={(newValue: Dayjs | null) => setBirthday((newValue as Dayjs))}
                />

                <IconButton color="primary" onClick={onNext}>
                    <ArrowCircleRightIcon sx={{fontSize: 60}}/>
                </IconButton>
            </Stack>
        </LocalizationProvider>
    )
}