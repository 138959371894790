import React, {useEffect, useState} from "react";
import {Box, Container, Stack} from "@mui/material";
import StatsView from "../stats/StatsView";
import {NutritionState} from "../person/models";
import {getUser} from "../user/userService";
import {useNavigate} from "react-router-dom";
import {User} from "../user/models";

export default function Dashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null)
    const [calories, setCalories] = useState(0)
    const [fat, setFat] = useState(0)
    const [carbs, setCarbs] = useState(0)
    const [proteins, setProteins] = useState(0)

    useEffect(() => {
        if (user == null) {
            getUser()
                .then((user) => {
                    setUser(user)
                })
                .catch((error) => {
                    console.log(error);
                    // TODO
                    //if (error.response.status === 404) {
                    navigate("/onboarding")
                    //}
                });
        }
    }, [user]);

    if (user == null) {
        return <></>
    }
    return (
        <React.Fragment>
            <Stack position="fixed" sx={{backgroundColor : "#F7D9BB", width: "100%", height: "120px"}}>
            </Stack>
            <Container sx={{pt: "120px", pb: "80px"}}>

            </Container>
            <Stack position="fixed" sx={{backgroundColor : "#ffffff", width: "100%", height: "80px", left: 0, bottom: 0}}>
                <StatsView
                    user={user}
                    state={new NutritionState({calories: calories, fat: fat, carbs: carbs, proteins: proteins})}/>
            </Stack>
        </React.Fragment>
    )
}