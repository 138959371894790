import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper, Stack, TableContainer, Typography} from "@mui/material";
import {ProductDetails} from "./models";

const novaLabels = new Map([
    ["1", "Żywność nieprzetworzona lub minimalnie przetworzona"],
    ["2", "Przetworzone składniki kulinarne"],
    ["3", "Żywność przetworzona"],
    ["4", "Ultra-przetworzona żywność"],
    ["unknown", "Poziom przetwarzania żywności nieznany"],
])

const nutriscoreLabels = new Map([
    ["a", "Bardzo dobra jakość odżywcza"],
    ["b", "Dobra jakość odżywcza"],
    ["c", "Średnia jakość odżywcza"],
    ["d", "Średnia jakość odżywcza"],
    ["e", "Zła jakość odżywcza"],
    ["unknown", "Brakuje Nieznany wynik Nutri-Score"],
])

export default function ProductDetailsView({productDetails}: { productDetails: ProductDetails }) {
    const items = [
        {title: "Tłuszcz", value: `${productDetails.nutriments.fat} g`},
        {
            title: "Kwasy tłuszczowe nasycone",
            value: `${productDetails.nutriments.saturatedFat} g`,
            backgroundColor: "#F9F9F9",
            pl: 4
        },
        {title: "Węglowodany", value: `${productDetails.nutriments.carbohydrates} g`},
        {title: "Cukry", value: `${productDetails.nutriments.sugars} g`, backgroundColor: "#F9F9F9", pl: 4},
        {title: "Błonnik", value: `${productDetails.nutriments.fiber} g`},
        {title: "Białko", value: `${productDetails.nutriments.proteins} g`},
        {title: "Sól", value: `${productDetails.nutriments.salt} g`}
    ]

    return (
        <Stack direction="column" sx={{m: 1}} spacing={1}>
            <Typography variant="h5" align="center">
                {productDetails.productName}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between" sx={{pt: 3}}>
                <Typography><b>Kod kreskowy:</b></Typography>
                <Typography variant="subtitle2">{productDetails.code} (EAN / EAN-13)</Typography>
            </Stack>
            <Stack direction="column">
                <b>Składniki:</b><Typography variant="subtitle2">{productDetails.ingredientsText}</Typography>
            </Stack>
            {productDetails.stores.length > 0 && productDetails.stores[0].length > 0 &&
                <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                    <b>Sklepy:</b><Typography variant="subtitle2">{productDetails.stores.join(", ")}</Typography>
                </Stack>
            }
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <b>Marki:</b><Typography variant="subtitle2">{productDetails.brands.join(", ")}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <b>Ilość:</b><Typography variant="subtitle2">{productDetails.quantity}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <img src={`/image/nutriscore-${productDetails.nutriScore}.svg`} width={100} alt="nutriScore"/>
                <div>{nutriscoreLabels.get(productDetails.nutriScore)}</div>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <img src={`/image/nova-group-${productDetails.novaGroup}.svg`} width={30} alt="novaGroup"/>
                <div>{novaLabels.get(productDetails.novaGroup)}</div>
            </Stack>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{backgroundColor: "#F5F5F5"}}>
                        <TableRow>
                            <TableCell>WARTOŚCI ODŻYWCZE</TableCell>
                            <TableCell align="right">na 100 g</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow style={{backgroundColor: item.backgroundColor}}
                                      sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" sx={{pl: item.pl}}>{item.title}</TableCell>
                                <TableCell align="right">{item.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}