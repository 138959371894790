import {IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import React from "react";

export default function ActivitySelectorView({activity, setActivity, onNext}: {
    activity: string,
    setActivity: (value: string) => void,
    onNext: () => void
}) {
    return (
        <Stack
            width="100%"
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
        >
            <Typography variant="h4" align="center">
                Jaki jest Twój poziom aktywnosci?
            </Typography>

            <ToggleButtonGroup
                sx={{textTransform: 'none', p: 2}}
                exclusive
                orientation="vertical"
                value={activity.toString()}
                onChange={(e, newAlignment) => {
                    setActivity(newAlignment)
                }}
            >
                <ToggleButton value="Sedentary">
                    <Stack direction="column">
                        <Typography variant="body1" color="black">
                            <b>Siedzący</b>
                        </Typography>
                        <Typography variant="body2" sx={{textTransform: 'none'}}>
                            Codzienne czynności wymagające minimalnego wysiłku, takie jak odpoczynek, praca przy biurku lub prowadzenie pojazdu.
                        </Typography>
                    </Stack>
                </ToggleButton>
                <ToggleButton value="LightlyActive">
                    <Stack direction="column">
                        <Typography variant="body1" color="black">
                            <b>Mało Aktywny</b>
                        </Typography>
                        <Typography variant="body2" sx={{textTransform: 'none'}}>
                            Codziennych czynności wymagające niewielkiego wysiłku, takie jak przedłużone stanie, prace domowe czy niektóre lekkie ćwiczenia.
                        </Typography>
                    </Stack>
                </ToggleButton>
                <ToggleButton value="ModeratelyActive">
                    <Stack direction="column">
                        <Typography variant="body1" color="black">
                            <b>Aktywny</b>
                        </Typography>
                        <Typography variant="body2" sx={{textTransform: 'none'}}>
                            Codzienne czynności wymagające wysiłku, takie jak stanie, praca fizyczna lub regularne i umiarkowane ćwiczenia.
                        </Typography>
                    </Stack>
                </ToggleButton>
                <ToggleButton value="VeryActive">
                    <Stack direction="column">
                        <Typography variant="body1" color="black">
                            <b>Bardzo Aktywny</b>
                        </Typography>
                        <Typography variant="body2" sx={{textTransform: 'none'}}>
                            Codzienne czynności wymagające intensywnego
                            wysiłku fizycznego, takie jak prace budowlane lub regularne intensywne ćwiczenia.
                        </Typography>
                    </Stack>
                </ToggleButton>
                <ToggleButton value="ExtraActive">
                    <Stack direction="column">
                        <Typography variant="body1" color="black">
                            <b>Bardzo Bardzo Aktywny</b>
                        </Typography>
                        <Typography variant="body2" sx={{textTransform: 'none'}}>
                            Intensywne treningi 6 do 7 dni w tygodniu wraz z pracą wymagającą aktywności fizycznej.
                        </Typography>
                    </Stack>
                </ToggleButton>
            </ToggleButtonGroup>

            <IconButton color="primary" onClick={onNext}>
                <ArrowCircleRightIcon sx={{fontSize: 60}}/>
            </IconButton>
        </Stack>
    )
}