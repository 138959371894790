export class Nutrition {
    readonly calories: number;
    readonly proteins: number;
    readonly fat: number;
    readonly carbs: number;
    readonly proteinsRange: number[];
    readonly fatRange: number[];
    readonly carbsRange: number[];

    constructor(json: {
        calories: number,
        proteins: number,
        fat: number,
        carbs: number,
        proteinsRange: number[],
        fatRange: number[],
        carbsRange: number[],
    }) {
        this.calories = json.calories
        this.proteins = json.proteins
        this.fat = json.fat
        this.carbs = json.carbs
        this.proteinsRange = json.proteinsRange
        this.fatRange = json.fatRange
        this.carbsRange = json.carbsRange
    }
}

export class NutritionState {
    readonly calories: number;
    readonly proteins: number;
    readonly fat: number;
    readonly carbs: number;

    constructor(json: {
        calories: number,
        proteins: number,
        fat: number,
        carbs: number,
    }) {
        this.calories = json.calories
        this.proteins = json.proteins
        this.fat = json.fat
        this.carbs = json.carbs
    }
}

export class WeightInfo {
    readonly status: string
    readonly normalRange: number[];

    constructor(json: { status: string, normalRange: number[] }) {
        this.status = json.status
        this.normalRange = json.normalRange
    }
}

export class Person {
    readonly age: number;
    readonly weightInfo: WeightInfo;
    readonly bmi: number;
    readonly nutrition: Nutrition;

    constructor(json: {
        age: number,
        weightInfo: { status: string, normalRange: number[] },
        bmi: number,
        nutrition: {
            calories: number,
            proteins: number,
            fat: number,
            carbs: number,
            proteinsRange: number[],
            fatRange: number[],
            carbsRange: number[],
        }
    }) {
        this.age = json.age;
        this.weightInfo = new WeightInfo(json.weightInfo);
        this.bmi = json.bmi;
        this.nutrition = new Nutrition(json.nutrition);
    }
}