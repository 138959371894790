import api from "../api";
import {UpdateUserRequest, User} from "./models";

export async function getUser(): Promise<User> {
    const response = await api.get("/users/me");
    return response.data
}

export async function updateUser(updateRequest: UpdateUserRequest): Promise<User> {
    const response = await api.post("/users/me", updateRequest);
    return response.data
}