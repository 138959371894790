export class Product {
    readonly id: string;
    readonly productName: string;
    readonly brands: string[];
    readonly quantity: string;
    readonly nutriScore: string;
    readonly novaGroup: string;

    constructor(json: {
        id: string, productName: string, brands: string[],
        quantity: string, nutriScore: string, novaGroup: string
    }) {
        this.id = json.id;
        this.productName = json.productName;
        this.brands = json.brands;
        this.quantity = json.quantity;
        this.nutriScore = json.nutriScore;
        this.novaGroup = json.novaGroup;
    }
}

export class Nutriments {
    readonly calories: number;
    readonly proteins: number;
    readonly fat: number;
    readonly saturatedFat: number;
    readonly carbohydrates: number;
    readonly sugars: number;
    readonly fiber: number;
    readonly salt: number;

    constructor(json: {
        calories: number, proteins: number, fat: number, saturatedFat: number,
        carbohydrates: number, sugars: number, fiber: number, salt: number
    }) {
        this.calories = json.calories;
        this.proteins = json.proteins;
        this.fat = json.fat;
        this.saturatedFat = json.saturatedFat;
        this.carbohydrates = json.carbohydrates;
        this.sugars = json.sugars;
        this.fiber = json.fiber;
        this.salt = json.salt;
    }
}

export class ProductDetails extends Product {
    readonly code: string;
    readonly nutriments: Nutriments;
    readonly servingSize: string;
    readonly ingredientsText: string;
    readonly stores: string[];

    constructor(json: {
        id: string, productName: string, brands: string[], quantity: string,
        nutriScore: string, novaGroup: string, code: string, nutriments: Nutriments,
        servingSize: string, ingredientsText: string, stores: string[]
    }) {
        super({
            id: json.id,
            productName: json.productName,
            brands: json.brands,
            quantity: json.quantity,
            nutriScore: json.nutriScore,
            novaGroup: json.novaGroup,
        });
        this.code = json.code;
        this.nutriments = json.nutriments;
        this.servingSize = json.servingSize;
        this.ingredientsText = json.ingredientsText;
        this.stores = json.stores;
    }
}