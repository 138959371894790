import React from "react";

import {LinearProgress} from "@mui/material";

type OverflowLinearProgressProps = {
    progress: number
    primaryColor: string
    secondaryColor?: string
    overflowColor?: string
}

export default function OverflowLinearProgress({
                                                   progress,
                                                   primaryColor,
                                                   secondaryColor = "#E7E8E8",
                                                   overflowColor = "#EB4A41",
                                               }: OverflowLinearProgressProps
) {
    if (progress <= 100) {
        return <LinearProgress variant="determinate" value={progress} sx={{
            backgroundColor: secondaryColor,
            "& .MuiLinearProgress-bar": {backgroundColor: primaryColor},
            "border-radius": "30px"
        }}/>
    } else if (progress < 200) {
        return <LinearProgress variant="determinate" value={progress - 100} sx={{
            backgroundColor: primaryColor,
            "& .MuiLinearProgress-bar": {backgroundColor: overflowColor},
            "border-radius": "30px"
        }}/>
    } else {
        return <LinearProgress variant="determinate" value={100} sx={{
            "& .MuiLinearProgress-bar": {backgroundColor: overflowColor},
            "border-radius": "30px"
        }}/>
    }
}