import {Button, Stack} from "@mui/material";
import React from "react";

export default function WelcomePage() {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{height: "100vh"}}
        >
            <Stack direction="column" spacing={2}>
                <Button variant="contained" href="/search">Search</Button>
                <Button variant="contained" href="/products/20411978">Product details</Button>
                <Button variant="contained" href="/onboarding">Onboarding</Button>
            </Stack>
        </Stack>
    );
}