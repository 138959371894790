import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.PUBLIC_URL}/api`
});

api.interceptors.request.use(
    (config) => {
        const storedToken = localStorage.getItem("token")
        if (!storedToken) return config

        const token = JSON.parse(storedToken)
        if (token) {
            config.headers.Authorization = `Bearer ${token.accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (err.response.status === 401) {
        localStorage.removeItem("token")
        window.location.href = "/login";
        return Promise.reject(error)
    }
});

export default api