import {useEffect, useMemo, useRef} from "react";
import debounce from "lodash.debounce";

export const useDebounce = (wait: number, callback: () => void) => {
    const ref = useRef<() => void>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    return useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, wait);
    }, [wait]);
};