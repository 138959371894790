import {Dayjs} from "dayjs";
import {Nutrition, WeightInfo} from "../person/models";

export class User {
    readonly email: string;
    readonly age: number;
    readonly weightInfo: WeightInfo;
    readonly weight: number;
    readonly height: number;
    readonly bmi: number;
    readonly nutrition: Nutrition;
    readonly birthday: Dayjs;
    readonly gender: string;
    readonly activity: string;
    readonly goal: string;

    constructor(json: {
        email: string,
        age: number,
        weightInfo: { status: string, normalRange: number[] },
        weight: number,
        height: number,
        bmi: number,
        nutrition: {
            calories: number,
            proteins: number,
            fat: number,
            carbs: number,
            proteinsRange: number[],
            fatRange: number[],
            carbsRange: number[],
        },
        readonly birthday: Dayjs;
        readonly gender: string;
        readonly activity: string;
        readonly goal: string;
    }) {
        this.email = json.email;
        this.age = json.age;
        this.weightInfo = new WeightInfo(json.weightInfo);
        this.weight = json.weight;
        this.height = json.height;
        this.bmi = json.bmi;
        this.nutrition = new Nutrition(json.nutrition);
        this.birthday = json.birthday;
        this.gender = json.gender;
        this.activity = json.activity;
        this.goal = json.goal;
    }
}

export class UpdateUserRequest {
    readonly email: string;
    readonly birthday: string;
    readonly weight: number;
    readonly height: number;
    readonly gender: string;
    readonly activity: string;
    readonly goal: string;

    constructor(
        email: string,
        birthday: string,
        weight: number,
        height: number,
        gender: string,
        activity: string,
        goal: string,
    ) {
        this.email = email;
        this.weight = weight;
        this.height = height;
        this.birthday = birthday;
        this.gender = gender;
        this.activity = activity;
        this.goal = goal;
    }
}