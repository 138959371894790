import React from "react";
import {Grid, Typography} from "@mui/material";

import OverflowLinearProgress from "../component/OverflowLinearProgress";
import {NutritionState, Person} from "../person/models";
import {User} from "../user/models";

export default function StatsView({user, state}: { user: User, state: NutritionState }) {
    const caloriesProgress = Math.floor(state.calories / (user.nutrition.calories / 100))

    let proteinsProgress
    if (state.proteins < user.nutrition.proteinsRange[0]) {
        proteinsProgress = Math.floor(state.proteins / (user.nutrition.proteinsRange[0] / 100))
    } else if (state.proteins >= user.nutrition.proteinsRange[0] && state.proteins <= user.nutrition.proteinsRange[1]) {
        proteinsProgress = 100
    } else {
        proteinsProgress = Math.floor(state.proteins / (user.nutrition.proteinsRange[1] / 100))
    }

    let fatProgress
    if (state.fat < user.nutrition.fatRange[0]) {
        fatProgress = Math.floor(state.fat / (user.nutrition.fatRange[0] / 100))
    } else if (state.fat >= user.nutrition.fatRange[0] && state.fat <= user.nutrition.fatRange[1]) {
        fatProgress = 100
    } else {
        fatProgress = Math.floor(state.fat / (user.nutrition.fatRange[1] / 100))
    }

    let carbsProgress
    if (state.carbs < user.nutrition.carbsRange[0]) {
        carbsProgress = Math.floor(state.carbs / (user.nutrition.carbsRange[0] / 100))
    } else if (state.carbs >= user.nutrition.carbsRange[0] && state.carbs <= user.nutrition.carbsRange[1]) {
        carbsProgress = 100
    } else {
        carbsProgress = Math.floor(state.carbs / (user.nutrition.carbsRange[1] / 100))
    }

    return (
        <Grid container columns={4} sx={{p: 1}} columnSpacing={2} rowSpacing={0.5}>
            <Grid item xs={1}>
                <OverflowLinearProgress primaryColor="#7BD281" progress={caloriesProgress}/>
            </Grid>
            <Grid item xs={1}>
                <OverflowLinearProgress primaryColor="#9DDBF8" progress={proteinsProgress}/>
            </Grid>
            <Grid item xs={1}>
                <OverflowLinearProgress primaryColor="#F3B170" progress={fatProgress}/>
            </Grid>
            <Grid item xs={1}>
                <OverflowLinearProgress primaryColor="#BA99CD" progress={carbsProgress}/>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2">
                    Kcal <b>{state.calories}</b>
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2">
                    Białka <b>{Math.round(state.proteins * 10) / 10}</b>
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2">
                    Tł. <b>{Math.round(state.fat * 10) / 10}</b>
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2">
                    Węgl. <b>{Math.round(state.carbs * 10) / 10}</b>
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2" sx={{color: "#979898"}}>
                    /{user.nutrition.calories} kcal
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2" sx={{color: "#979898"}}>
                    /{Math.floor(user.nutrition.proteinsRange[0])}-{Math.floor(user.nutrition.proteinsRange[1])} g
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2" sx={{color: "#979898"}}>
                    /{Math.floor(user.nutrition.fatRange[0])}-{Math.floor(user.nutrition.fatRange[1])} g
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2" sx={{color: "#979898"}}>
                    /{Math.floor(user.nutrition.carbsRange[0])}-{Math.floor(user.nutrition.carbsRange[1])} g
                </Typography>
            </Grid>
        </Grid>
    )
}