import {
    FormControl, FormHelperText,
    IconButton,
    InputAdornment, OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import React from "react";

export default function WeightSelectorView({weight, setWeight, onNext}: {
    weight: string,
    setWeight: (value: string) => void,
    onNext: () => void
}) {
    return (
        <Stack
            width="100%"
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
        >
            <Typography variant="h4" align="center">
                Jaka jest twoja aktualna waga?
            </Typography>

            <FormControl sx={{ width: '25ch'}} variant="outlined">
                <OutlinedInput
                    id="outlined-adornment-weight"
                    type="number"
                    value={weight}
                    onChange={(e) => {setWeight(e.target.value)}}
                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    autoFocus
                    inputProps={{
                        'aria-label': 'waga',
                        inputMode: 'numeric',
                    }}
                />
                <FormHelperText id="outlined-weight-helper-text">Waga</FormHelperText>
            </FormControl>

            <IconButton color="primary" onClick={onNext} disabled={weight.length === 0}>
                <ArrowCircleRightIcon sx={{fontSize: 60}}/>
            </IconButton>
        </Stack>
    )
}