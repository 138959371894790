import BarcodeScanner from "./search/BarcodeScanner";
import {Link, Navigate, Outlet, Route, Routes} from "react-router-dom";
import SearchView from "./search/SearchView";
import React, {useContext, useState} from "react";
import ProductDetailsPage from "./product/ProductDetailsPage";
import WelcomePage from "./WelcomePage";
import OnboardingPage from "./onboarding/OnboardingPage";
import {AuthContext} from "./auth/AuthContextProvider";
import Registration from "./auth/Registration";
import Login from "./auth/Login";
import Dashboard from "./dashboard/Dashboard";
import InfiniteSlider from "./component/InfiniteSlider";

export default function App() {
    const [barcode, setBarcode] = useState("");

    return (
        <Routes>
            <Route path="/" element={<Index/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/registration" element={<Registration/>}/>

            <Route element={<ProtectedRoute/>}>
                <Route path="/welcome" element={<WelcomePage/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/search" element={<SearchView barcode={barcode} setBarcode={setBarcode}/>}/>
                <Route path="/scan" element={<BarcodeScanner onScan={(code) => setBarcode(code)}/>}/>
                <Route path="/products/:id" element={<ProductDetailsPage/>}/>
                <Route path="/onboarding" element={<OnboardingPage/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

function ProtectedRoute() {
    const auth = useContext(AuthContext);

    if (!auth.token.accessToken) {
        return <Navigate to={{pathname: "/login"}}/>;
    }

    return <Outlet/>;
}

function Index() {
    const auth = useContext(AuthContext);

    if (!auth.token.accessToken) {
        return <Navigate to={{pathname: "/login"}}/>;
    }

    return <Navigate to={{pathname: "/dashboard"}}/>;
}

function NotFound() {
    return (
        <>
            <h1>404 — Page Not Found</h1>
            <Link to="/">Take me back!</Link>
        </>
    );
}