import api from "../api";
import Token from "./token";

export async function register(creds: { email: String, password: String }): Promise<Token> {
    const response = await api.post("/users", creds);
    return response.data
}

export async function login(creds: { email: String, password: String }): Promise<Token> {
    const response = await api.post("/auth", {
        username: creds.email,
        password: creds.password,
    }, {
        params: {
            grantType: "password"
        }
    });
    return response.data
}