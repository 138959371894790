import GenderSelectorView from "./GenderSelectorView";
import React, {useEffect, useState} from "react";
import WeightSelectorView from "./WeightSelectorView";
import HeightSelectorView from "./HeightSelectorView";
import BirthdaySelectorView from "./BirthdaySelectorView";
import ActivitySelectorView from "./ActivitySelectorView";
import {Button, Stack} from "@mui/material";
import {KeyboardArrowLeft} from "@mui/icons-material";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {updateUser} from "../user/userService";
import {UpdateUserRequest, User} from "../user/models";

export default function OnboardingPage() {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [gender, setGender] = useState("")
    const [weight, setWeight] = useState("")
    const [height, setHeight] = useState("")
    const [birthday, setBirthday] = useState(dayjs().subtract(24, 'years'))
    const [activity, setActivity] = useState("Sedentary")
    const [goal, setGoal] = useState("MaintainCurrentWeight")
    const [user, setUser] = useState<User | null>(null)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    function preventDefault(e: TouchEvent) {
        e.preventDefault()
    }

    function handleResize() {
        if (window.visualViewport != null) {
            if (window.visualViewport.height < windowHeight) {
                window.addEventListener('touchmove', preventDefault, { passive: false })
                window.scrollTo(0, 0)
            } else {
                window.removeEventListener('touchmove', preventDefault)
            }
            setWindowHeight(window.visualViewport.height);
        }
    }

    useEffect(() => {
        if (window.visualViewport !=  null) {
            window.visualViewport.addEventListener("resize", handleResize);
        }

       return () => window.removeEventListener('resize', handleResize);
    });

    useEffect(() => {
        if (activeStep >= 5) {
            const updateRequest = new UpdateUserRequest("", birthday.toISOString(), +weight, +height, gender, activity, goal)
            updateUser(updateRequest)
                .then(result => setUser(result))
                .catch((error) => {
                    console.log(error.message);
                });
        }
    }, [activeStep, goal])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let currentView
    switch (activeStep) {
        case 0:
            currentView = <GenderSelectorView gender={gender} setGender={setGender} onNext={handleNext}/>
            break
        case 1:
            currentView = <WeightSelectorView weight={weight} setWeight={setWeight} onNext={handleNext}/>
            break
        case 2:
            currentView = <HeightSelectorView height={height} setHeight={setHeight} onNext={handleNext}/>
            break
        case 3:
            currentView = <BirthdaySelectorView birthday={birthday} setBirthday={setBirthday} onNext={handleNext}/>
            break
        case 4:
            currentView = <ActivitySelectorView activity={activity} setActivity={setActivity} onNext={handleNext}/>
            break
        default:
            if (user == null) {
                currentView = <></>
            } else {
                navigate("/dashboard")
            }
    }

    return (
        <Stack direction="column" sx={{height: windowHeight}}>
            {activeStep > 0 && activeStep < 5 &&
                <Stack direction="row" sx={{minHeight: 40}}>
                    <Button size="medium" onClick={handleBack}>
                        <KeyboardArrowLeft/>
                        Cofnij
                    </Button>
                </Stack>
            }
            <Stack
                width="100%"
                height="100%"
                pt={1}
            >
                {currentView}
            </Stack>
        </Stack>
    )
}