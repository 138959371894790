import React from "react";
import {useZxing} from "react-zxing";
import {useMediaDevices} from "react-media-devices";
import {useNavigate} from "react-router-dom";
import {Container, Stack} from "@mui/material";

export default function BarcodeScanner({onScan}: { onScan: (code: string) => void }) {
    const navigate = useNavigate()
    const {devices} = useMediaDevices({constraints: {video: true}});
    const deviceId = devices?.[0]?.deviceId;

    const {ref} = useZxing({
        deviceId,
        onDecodeResult(result) {
            onScan(result.getText());
            navigate("/search", {replace: true})
        },
    });

    return <Stack position="relative" sx={{height: "100vh"}}>
        <video ref={ref}/>
        <Stack
            position="absolute"
            sx={{width: "100%", height: "100%", zIndex: 10}}
            alignItems="center"
            justifyContent="space-between"
        >
            <Container sx={{width: "100%", height: "35%", backgroundColor: "rgba(0, 0, 0, 0.5)"}}></Container>
            <Stack
                sx={{width: "100%", height: "30%"}}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
            >
                <Container sx={{width: "10%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)"}}></Container>
                <span style={{width: "100%", height: "4px", backgroundColor: "#ff0000"}}/>
                <Container sx={{width: "10%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)"}}></Container>
            </Stack>
            <Container sx={{width: "100%", height: "35%", backgroundColor: "rgba(0, 0, 0, 0.5)"}}></Container>
        </Stack>
    </Stack>
}